import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { ParecerResponsavelBuilder } from "src/app/builder/parecerresponsavel.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { CasaComunitaria } from "src/app/model/casacomunitaria.model";
import { MembroComunidade } from "src/app/model/membro.comunidade.model";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Usuario } from "src/app/model/usuario.model";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UsuarioService } from "src/app/secured/wop/usuario/service/usuario.service";
import { ImagemService } from "src/app/secured/wop/imagem/service/imagem.service";
import { PessoaService } from "src/app/secured/wop/pessoa/service/pessoa.service";
import { CasaComunitariaService } from "src/app/secured/wop/casacomunitaria/service/casacomunitaria.service";
import { MembroComunidadeService } from "src/app/secured/wop/celula/service/membro.comunidade.service";
import { QuestionarioFormandoService } from "../service/questionarioformando.service";
import { ParecerResponsavelService } from "../../parecer/service/parecerresponsavel.service";
import { ElementoGrupoDominioLocalService } from "../../grupodominio/service/elementogrupodominio.local.service";
import { CaminhoFormativoService } from "../service/caminhoformativo.service";
import { DiscernimentoService } from "../../discernimentos/service/discernimento.service";
import { ArquivoService } from "../service/arquivo.service";

@Component({
  selector: "app-parecer",
  templateUrl: "./parecer.rr.autoridade.cs.component.html",
  styleUrls: [],
})
export class ParecerResponsavelRegionalAutoridadeCasaComunitariaComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public casacomunitaria: CasaComunitaria;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCasaComunitaria: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public paginacaoConfig: any;
  public buscaCasaComunitaria: any;

  public totalDePaginasMembros: number;
  public hasCasaComunitariaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public questionarioId: number;
  public questionariosMembros: Array<any>;

  public listaCorStatusVocacional!: Array<any>;

  public sanitizedPdfUrl: SafeResourceUrl;
  @ViewChild("pdfViewer", { static: false })
  pdfViewer: ElementRef; 

  constructor(
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public casacomunitariaService: CasaComunitariaService,
    public membroService: MembroComunidadeService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public caminhoFormativoService: CaminhoFormativoService,
    public discernimentoService: DiscernimentoService,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,    
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCasaComunitaria = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCasaComunitariaAssociada = false;
  }

  async initializer(): Promise<any> {
    this.buscaCasaComunitaria = {
      nome: null,
      ano: null,
      faseId: null,
      regionalId: null,
      missaoId: null,
      centroEvangelizacaoId: null,
      paisId: null,
      estadoId: null,
      pageNumber: 0,
      quantityOfElements: 300,
    };
    this.paginacaoConfig = {
      page: 0,
      size: 10,
      totalPages: 0,
      totalElements: 0,
    };
    await this.carregarUsuario();
    await this.carregarPessoa();
    this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
    await this.carregarCorStatusVocacional();
    await this.carregarMembrosCasaComunitaria();
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarCorStatusVocacional(): Promise<any> {
    try {
      this.listaCorStatusVocacional =
        await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
          "COR_STATUS_VOCACIONAL"
        );
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMembrosCasaComunitaria(): Promise<any> {
    try {
      let response =
      await this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelRegionalPorTipo(
        'CASACOMUNITARIA',
        this.questionarioId,
        this.paginacaoConfig.page,
        this.paginacaoConfig.size
      );
      this.membrosCasaComunitaria = response.content;
      this.paginacaoConfig.totalPages = response.totalPages;
      this.paginacaoConfig.totalElements = response.totalElements;

      if (this.membrosCasaComunitaria.length > 0) {
        this.membrosCasaComunitaria = this.membrosCasaComunitaria.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
        if (this.membrosCasaComunitaria[i].imagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.membrosCasaComunitaria[i].imagemId
          );
          this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
        }

        this.membrosCasaComunitaria[i].editando = false;

        this.membrosCasaComunitaria[i].indicacaoFCNome = this.buscarNomePedido(this.membrosCasaComunitaria[i].indicacaoFC);
        this.membrosCasaComunitaria[i].indicacaoCLNome = this.buscarNomePedido(this.membrosCasaComunitaria[i].indicacaoCL);
        this.membrosCasaComunitaria[i].indicacaoCRNome = this.buscarNomePedido(this.membrosCasaComunitaria[i].indicacaoCR);

        if(this.membrosCasaComunitaria[i].corStatusVocacionalId){
          const cor = this.listaCorStatusVocacional.find(c => c.id === this.membrosCasaComunitaria[i].corStatusVocacionalId);
          this.membrosCasaComunitaria[i].corStatusVocacionalValor = cor ? cor.valor : undefined;
          this.membrosCasaComunitaria[i].corStatusVocacionalNome = cor ? cor.nome : undefined;
        } 

        if (
          this.membrosCasaComunitaria[i].statusQuestionario == "NAOINICIADO"
          || this.membrosCasaComunitaria[i].statusQuestionario == undefined
          || this.membrosCasaComunitaria[i].statusQuestionario == null
        ) {
          this.membrosCasaComunitaria[i].statusQuestionario =
            "Não Iniciado";
        }
        if (this.membrosCasaComunitaria[i].statusParecerFC == "NAOINICIADO"            
          || this.membrosCasaComunitaria[i].statusParecerFC == undefined
          || this.membrosCasaComunitaria[i].statusParecerFC == null
        ) {
          this.membrosCasaComunitaria[i].statusParecerFC = "Não Iniciado";
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  buscarNomePedido(idPedido: number): string {
    let nomePedido = '';
    if(idPedido)
    {
      switch (idPedido) {
        case 1:
          nomePedido = 'Mudança de Nível';
          break;      
        case 2:
          nomePedido = 'Permanecer no Nível';
          break;  
        case 3:
          nomePedido = 'Realizar Primeiras Promessas';
          break;      
        case 4:
          nomePedido = 'Realizar Promessas Definitivas';
          break;
        case 5:
          nomePedido = 'Desligamento CAL';
          break;   
        case 6:
          nomePedido = 'Outro';
          break;                               
        default:
          nomePedido = null;
          break;
      }
    }
    return nomePedido;
  }

  buscarNomeQuestionario(idQuestionario: number): string {
    let nomeQuestionario = '';
    if(idQuestionario)
    {
      switch (idQuestionario) {
        case 4:
          nomeQuestionario = 'Pedido para as Primeiras Promessas - CA';
          break;      
        case 5:
          nomeQuestionario = 'Pedido para as Promessas Definitivas - CA';
          break;  
        case 6:
          nomeQuestionario = 'Postulantes/Ingresso no Discipulado - CA';
          break;      
        case 7:
          nomeQuestionario = 'Discipulos de Primeiro Ano - CA';
          break;
        case 8:
          nomeQuestionario = 'Renovação das Promessas - CA';
          break;                        
        default:
          nomeQuestionario = null;
          break;
      }
    }
    return nomeQuestionario;
  }
  
  
  async carregarQuestionarioMembro(idMembros:any): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorMembrosId(idMembros);
      this.questionariosMembros = response;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarArquivoPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async cadastrarConsideracaoConselhoLocal(membro): Promise<any> {
    try {
      await this.atualizarQuestionarioFormando(
        membro
      );
      /*await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
        membro
      ); */     
      await this.carregarMembrosCasaComunitaria();
      this.swtAlert2Service.successAlert(
        "Parecer de consideração do Conselho Local atualizado com sucesso!"
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarQuestionarioFormando(membro: any): Promise<any> {
    try {
      let responseQuestionario = await this.questionarioFormandoService.buscarPorId(membro.idQuestionarioFormando);
      this.questionarioFormando = responseQuestionario;
      if (this.questionarioFormando.id != null) {
        this.questionarioFormando.indicacaoCR = membro.indicacaoCR;
        this.questionarioFormando.justificativaCR = membro.justificativaCR;
        this.questionarioFormando.isDiscernimentoGG = membro.isDiscernimentoGG;
        this.questionarioFormando.consideracaoConcorda = membro.consideracaoConcorda;
        this.questionarioFormando.caminhoFormativoFeedback = membro.caminhoFormativoFeedback;
        this.questionarioFormando.caminhoFormativoSugeridoFC = membro.caminhoFormativoSugeridoFC;
        this.questionarioFormando.consideracaoCL = membro.consideracaoCL; 
        this.questionarioFormando.corStatusVocacionalId = membro.corStatusVocacionalId;
        let response = await this.questionarioFormandoService.atualizar(
          this.questionarioFormando.id, this.questionarioFormando
        );
        this.questionarioFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.carregarMembrosCasaComunitaria();
  }

  concordanciaConselhoLocal(membro: any) {
    if (membro.consideracaoConcorda === true) {
      membro.caminhoFormativoFeedback =
        membro.caminhoFormativoSugeridoFC;
      membro.consideracaoCL = "";
    } else {
      membro.caminhoFormativoFeedback = "";
    }
  }

  copiarTextoConsideracaoConselhoLocalParaFeedback(membro: any) {
    membro.caminhoFormativoFeedback = membro.consideracaoCL;
  }

  obterCorDaLinha(membro: any) {
    let cssObject = {};

    if (membro.corStatusVocacionalValor != null) {
      cssObject["background-color"] = membro.corStatusVocacionalValor;
    }

    if (
      membro.corStatusVocacionalNome != null &&
      membro.corStatusVocacionalNome.localeCompare("Desligamento") == 0
    ) {
      cssObject["color"] = "#fff";
    }

    return cssObject;
  }

  truncateText(text: string | null | undefined, limit: number = 50): string {
    if (!text) {
      return '';
    }
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  }
}
