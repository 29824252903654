import { Component, OnInit, ViewChild } from '@angular/core';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { DadoVocacional } from 'src/app/model/dadovocacional.model';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Usuario } from 'src/app/model/usuario.model';
import { RespostaParecer } from 'src/app/model/respostaparecer.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { CaminhoFormativo } from 'src/app/model/caminhoformativo.model';
import { PessoaInfo } from 'src/app/model/pessoainfo.model';
import { Missao } from 'src/app/model/missao.model';
import { DadoFormandoCABuilder } from 'src/app/builder/dadoformandoca.builder';
import { CaminhoFormativoBuilder } from 'src/app/builder/caminhoformativo.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RespostaParecerBuilder } from 'src/app/builder/respostaparecer.builder';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';
import { WopGetAllService } from 'src/app/service/getall.service';
import { DadoFormandoCA } from 'src/app/model/dadoformandoca.model';
import { DadoFormandoCV } from 'src/app/model/dadoformandocv.model';
import { NgForm } from '@angular/forms';
import { ElementoGrupoDominioLocalService } from 'src/app/secured/local/grupodominio/service/elementogrupodominio.local.service';
import { DadoFormandoCAService } from 'src/app/secured/local/parecer/service/dadoformandoca.service';
import { CaminhoFormativoService } from 'src/app/secured/local/questionario/service/caminhoformativo.service';
import { RespostaParecerService } from 'src/app/secured/local/parecer/service/respostaparecer.service';
import { QuestionarioFormandoService } from 'src/app/secured/local/questionario/service/questionarioformando.service';
import { ParecerResponsavelService } from 'src/app/secured/local/parecer/service/parecerresponsavel.service';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { PaisService } from 'src/app/secured/wop/pais/service/pais.service';
import { EstadoService } from 'src/app/secured/wop/estado/service/estado.service';
import { CidadeService } from 'src/app/secured/wop/cidade/service/cidade.service';
import { ElementoGrupoDominioService } from 'src/app/secured/wop/grupodominio/service/elementogrupodominio.service';
import { MissaoService } from 'src/app/secured/wop/missao/service/missao.service';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { DadoVocacionalService } from 'src/app/secured/wop/dadosvocacionais/service/dadovocacional.service';

@Component({
  selector: 'app-parecer-cal-fc-answer',
  templateUrl: './parecer-cal-fc-answer-default.component.html',
  styleUrls: [],
})
export class ParecerCALFCAnswerDefaultComponent implements OnInit {
  @ViewChild('dadosPessoaisForm', { static: true })
  dadosPessoaisForm!: NgForm;

  public pessoaResponsavel: Pessoa;
  public missoesVinculo: Array<Missao>;
  public missoes: Array<Missao>;
  public pessoaFormandoId: number;
  public tabNumber: number;
  public imageChangedEvent: any;
  public croppedImage: any;
  public nextClicked: boolean;
  public url: string;
  public intlTelInputCalled: boolean = false;
  public intlTellInputInstance: any;
  public formasVida: Array<ElementoGrupoDominio>;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public dadoFormandoCA: DadoFormandoCA;
  public dadoFormandoCV: DadoFormandoCV;
  public caminhoFormativoFormando: CaminhoFormativo;
  public urlBaseImagem: string;
  public usuarioAtual: Usuario;
  public formando: Pessoa;

  public imageUrl: any;

  public tabNames: Array<string>;
  public activeTabNumber: number;

  public paises: Array<Pais>;
  public estados: Array<Estado>;
  public cidades: Array<Cidade>;

  public parecerResponsavelId: number;
  public parecerResponsavel: ParecerResponsavel;
  public questionarioFormando: QuestionarioFormando;
  public questionarioNome: string;

  public respostas: Array<RespostaParecer>;

  public resposta36: RespostaParecer;
  public resposta37: RespostaParecer;
  public resposta38: RespostaParecer;
  public resposta39: RespostaParecer;
  public resposta40: RespostaParecer;
  public resposta41: RespostaParecer;
  public resposta42: RespostaParecer;
  public resposta43: RespostaParecer;
  public resposta44: RespostaParecer;
  public resposta45: RespostaParecer;
  public resposta46: RespostaParecer;
  public resposta47: RespostaParecer;
  public resposta48: RespostaParecer;
  public resposta49: RespostaParecer;
  public resposta50: RespostaParecer;
  public resposta51: RespostaParecer;
  public resposta52: RespostaParecer;
  public resposta53: RespostaParecer;
  public resposta54: RespostaParecer;
  public resposta55: RespostaParecer;
  public resposta56: RespostaParecer;
  public resposta57: RespostaParecer;
  public resposta58: RespostaParecer;
  public resposta59: RespostaParecer;
  public resposta60: RespostaParecer;
  public resposta61: RespostaParecer;
  public resposta62: RespostaParecer;
  public resposta63: RespostaParecer;
  public resposta64: RespostaParecer;
  public resposta65: RespostaParecer;
  public resposta66: RespostaParecer;
  public resposta67: RespostaParecer;
  public resposta68: RespostaParecer;
  public resposta69: RespostaParecer;
  public resposta70: RespostaParecer;
  public resposta71: RespostaParecer;
  public resposta72: RespostaParecer;
  public resposta73: RespostaParecer;
  public resposta74: RespostaParecer;
  public resposta75: RespostaParecer;
  public resposta76: RespostaParecer;
  public resposta77: RespostaParecer;

  constructor(
    public wopGetAllPublic: WopGetAllService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public missaoService: MissaoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public dadoFormandoCAService: DadoFormandoCAService,
    public caminhoFormativoService: CaminhoFormativoService,
    public respostaParecerService: RespostaParecerService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelService: ParecerResponsavelService,
    public dadoFormandoCABuilder: DadoFormandoCABuilder,
    public caminhoFormativoFormandoBuilder: CaminhoFormativoBuilder,
    public respostaParecerBuilder: RespostaParecerBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.missoesVinculo = new Array<Missao>();
    this.missoes = new Array<Missao>();
    this.formasVida = new Array<ElementoGrupoDominio>();
    this.niveisFormacao = new Array<ElementoGrupoDominio>();
    this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
    this.caminhoFormativoFormando =
      this.caminhoFormativoFormandoBuilder.getInstance();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.nextClicked = false;

    this.pessoaResponsavel = this.pessoaBuilder.getInstance();
    this.formando = this.pessoaBuilder.getInstance();

    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.url = environment.usuario.selectPorUsername;

    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();

    this.tabNames = ['dados-pessoais-tab-content', 'questionario-tab-content'];

    this.activeTabNumber = 0;

    this.resposta36 = respostaParecerBuilder.getInstance();
    this.resposta37 = respostaParecerBuilder.getInstance();
    this.resposta38 = respostaParecerBuilder.getInstance();
    this.resposta39 = respostaParecerBuilder.getInstance();
    this.resposta40 = respostaParecerBuilder.getInstance();
    this.resposta41 = respostaParecerBuilder.getInstance();
    this.resposta42 = respostaParecerBuilder.getInstance();
    this.resposta43 = respostaParecerBuilder.getInstance();
    this.resposta44 = respostaParecerBuilder.getInstance();
    this.resposta45 = respostaParecerBuilder.getInstance();
    this.resposta46 = respostaParecerBuilder.getInstance();
    this.resposta47 = respostaParecerBuilder.getInstance();
    this.resposta48 = respostaParecerBuilder.getInstance();
    this.resposta49 = respostaParecerBuilder.getInstance();
    this.resposta50 = respostaParecerBuilder.getInstance();
    this.resposta51 = respostaParecerBuilder.getInstance();
    this.resposta52 = respostaParecerBuilder.getInstance();
    this.resposta53 = respostaParecerBuilder.getInstance();
    this.resposta54 = respostaParecerBuilder.getInstance();
    this.resposta55 = respostaParecerBuilder.getInstance();
    this.resposta56 = respostaParecerBuilder.getInstance();
    this.resposta57 = respostaParecerBuilder.getInstance();
    this.resposta58 = respostaParecerBuilder.getInstance();
    this.resposta59 = respostaParecerBuilder.getInstance();
    this.resposta60 = respostaParecerBuilder.getInstance();
    this.resposta61 = respostaParecerBuilder.getInstance();
    this.resposta62 = respostaParecerBuilder.getInstance();
    this.resposta63 = respostaParecerBuilder.getInstance();
    this.resposta64 = respostaParecerBuilder.getInstance();
    this.resposta65 = respostaParecerBuilder.getInstance();
    this.resposta66 = respostaParecerBuilder.getInstance();
    this.resposta67 = respostaParecerBuilder.getInstance();
    this.resposta68 = respostaParecerBuilder.getInstance();
    this.resposta69 = respostaParecerBuilder.getInstance();
    this.resposta70 = respostaParecerBuilder.getInstance();
    this.resposta71 = respostaParecerBuilder.getInstance();
    this.resposta72 = respostaParecerBuilder.getInstance();
    this.resposta73 = respostaParecerBuilder.getInstance();
    this.resposta74 = respostaParecerBuilder.getInstance();
    this.resposta75 = respostaParecerBuilder.getInstance();
    this.resposta76 = respostaParecerBuilder.getInstance();
    this.resposta77 = respostaParecerBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  imprimirValorDoForm() {
    console.log('Valor do Form', JSON.stringify(this.dadosPessoaisForm.value));
  }

  async initialization(): Promise<any> {
    await this.getUsuarioAtual();
    this.carregarFormasVida();
    this.carregarNiveisFormacao();

    await this.carregarMissoesVinculo();
    this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get('id'));
    await this.carregarParecerResponsavel();
    await this.carregarQuestionarioFormando();
    await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
    this.pessoaResponsavel = await this.carregarPessoaResponsavel(
      this.usuarioAtual.id
    );
    await this.carregarDadoVocacional();
    await this.carregarDadoFormandoCA();
    await this.carregarCaminhoFormativo();
    await this.carregarRespostas();
    // this.imprimirValorDoForm();
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMissoesVinculo(): Promise<any> {
    try {
      let response = await this.wopGetAllPublic.getAllMissao();
      this.missoesVinculo = response.entity;
      this.missoesVinculo = this.missoesVinculo.sort((a: Missao, b: Missao) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarPessoaFormando(pessoaId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorId(pessoaId);

      this.formando = response.entity;

      this.pessoaFormandoId = this.formando.id;

      if (this.formando.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.formando.imagemId);

        this.imagemService.createImage(blogImage, this.formando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarPessoaResponsavel(usuarioId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(usuarioId);

      return response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  carregarFormasVida(): void {
    let buscaFormaVida = {
      grupoNome: 'FORMA_VIDA',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFormaVida)
      .then((lista: any) => {
        this.formasVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarNiveisFormacao(): void {
    let busca = {
      grupoNome: 'NIVEL_FORMACAO',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.niveisFormacao = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  async carregarPaises(): Promise<any> {
    try {
      let response = await this.paisService.findAll();
      this.paises = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarEstados(paisId: number): Promise<any> {
    try {
      let request = {
        paisId: paisId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.estadoService.buscarEstados(request);
      this.estados = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async carregarCidades(estadoId: number): Promise<any> {
    try {
      let request = {
        estadoId: estadoId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.cidadeService.buscarCidades(request);
      this.cidades = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  logOnConsole(dadosPessoaisForm: any): void {
    console.log(dadosPessoaisForm);
  }

  activateTab(tab: string): void {
    $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
    $('.nav-tabs a[href="#' + tab + '"]').tab('show');
  }

  activateTab2(action: string): void {
    if (action.localeCompare('previous') == 0) {
      if (this.activeTabNumber > 0) {
        this.activeTabNumber -= 1;
      }
    } else if (action.localeCompare('next') == 0) {
      if (this.activeTabNumber < this.tabNames.length - 1) {
        this.activeTabNumber += 1;
      }
    }
    $(
      '.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]'
    ).removeClass('disabled');
    $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab(
      'show'
    );
  }

  async salvar(): Promise<any> {
    this.atualizarCaminhoFormativo();
    await this.atualizarParecerResponsavel('INICIADO');
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log('Imagem carregada');
  }

  cropperReady() {
    console.log('Imagem cortada');
  }

  loadImageFailed() {
    console.log('Carregamento da imagem falhou!');
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.formando.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async selecionarFormadorComunitario(pessoa: PessoaInfo): Promise<any> {
    //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    //this.pessoaFormadorComunitarioSelecionado = pessoa;
    //$('#listagem-pessoa-formador-comunitario').modal('hide');
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadoFormandoCA(): Promise<any> {
    try {
      let response = await this.dadoFormandoCAService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCA = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCaminhoFormativo(): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.caminhoFormativoFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarCaminhoFormativo(): Promise<any> {
    try {
      if (this.caminhoFormativoFormando.id != null) {
        let response = await this.caminhoFormativoService.atualizar(
          this.caminhoFormativoFormando
        );
        this.caminhoFormativoFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarQuestionarioFormando(): Promise<any> {
    try {
      if (this.questionarioFormando.id != null) {
        let response = await this.questionarioFormandoService.atualizar(
          this.questionarioFormando.id,
          this.questionarioFormando
        );
        this.questionarioFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRespostas(): Promise<any> {
    try {
      let response = await this.respostaParecerService.porPessoaIdEParecerId(
        this.pessoaFormandoId,
        this.parecerResponsavel.parecer_id
      );
      if (response != null && response != undefined) {
        this.respostas = response;
        this.respostas.forEach((resposta) => {
          switch (resposta.parecer_pergunta_id) {
            case 36:
              this.resposta36 = resposta;
              break;
            case 37:
              this.resposta37 = resposta;
              break;
            case 38:
              this.resposta38 = resposta;
              break;
            case 39:
              this.resposta39 = resposta;
              break;
            case 40:
              this.resposta40 = resposta;
              break;
            case 41:
              this.resposta41 = resposta;
              break;
            case 42:
              this.resposta42 = resposta;
              break;
            case 43:
              this.resposta43 = resposta;
              break;
            case 44:
              this.resposta44 = resposta;
              break;
            case 45:
              this.resposta45 = resposta;
              break;
            case 46:
              this.resposta46 = resposta;
              break;
            case 47:
              this.resposta47 = resposta;
              break;
            case 48:
              this.resposta48 = resposta;
              break;
            case 49:
              this.resposta49 = resposta;
              break;
            case 50:
              this.resposta50 = resposta;
              break;
            case 51:
              this.resposta51 = resposta;
              break;
            case 52:
              this.resposta52 = resposta;
              break;
            case 53:
              this.resposta53 = resposta;
              break;
            case 54:
              this.resposta54 = resposta;
              break;
            case 55:
              this.resposta55 = resposta;
              break;
            case 56:
              this.resposta56 = resposta;
              break;
            case 57:
              this.resposta57 = resposta;
              break;
            case 58:
              this.resposta58 = resposta;
              break;
            case 59:
              this.resposta59 = resposta;
              break;
            case 60:
              this.resposta60 = resposta;
              break;
            case 61:
              this.resposta61 = resposta;
              break;
            case 62:
              this.resposta62 = resposta;
              break;
            case 63:
              this.resposta63 = resposta;
              break;
            case 64:
              this.resposta64 = resposta;
              break;
            case 65:
              this.resposta65 = resposta;
              break;
            case 66:
              this.resposta66 = resposta;
              break;
            case 67:
              this.resposta67 = resposta;
              break;
            case 68:
              this.resposta68 = resposta;
              break;
            case 69:
              this.resposta69 = resposta;
              break;
            case 70:
              this.resposta70 = resposta;
              break;
            case 71:
              this.resposta71 = resposta;
              break;
            case 72:
              this.resposta72 = resposta;
              break;
            case 73:
              this.resposta73 = resposta;
              break;
            case 74:
              this.resposta74 = resposta;
              break;
            case 75:
              this.resposta75 = resposta;
              break;
            case 76:
              this.resposta76 = resposta;
              break;
            case 77:
              this.resposta77 = resposta;
              break;
          }
        });
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarParecerResponsavel(): Promise<any> {
    try {
      let response = await this.parecerResponsavelService.buscarPorId(
        this.parecerResponsavelId
      );
      if (response != null && response != undefined) {
        this.parecerResponsavel = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioFormando(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorId(
        this.parecerResponsavel.questionarioFormandoId
      );
      if (response != null && response != undefined) {
        this.questionarioFormando = response;
        switch (this.questionarioFormando.questionarioId) {
          case 4:
            this.questionarioNome = 'Pedido para as Primeiras Promessas - CA';
            break;
          case 5:
            this.questionarioNome = 'Pedido para as Promessas Definitivas - CA';
            break;
          case 6:
            this.questionarioNome = 'Postulantes/Ingresso no Discipulado - CA';
            break;
          case 7:
            this.questionarioNome = 'Discipulos de Primeiro Ano - CA';
            break;
          case 8:
            this.questionarioNome = 'Renovação das Promessas - CA';
            break;
          default:
            this.questionarioNome = null;
            break;
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarResposta(resposta: RespostaParecer): Promise<any> {
    try {
      resposta.pessoaId = this.pessoaFormandoId;
      if (resposta.id == null) {
        let response = await this.respostaParecerService.cadastrar(resposta);
        this.swtAlert2Service.successAlert(response.message);
        return response;
      } else {
        let response = await this.respostaParecerService.atualizar(
          resposta.id,
          resposta
        );
        this.swtAlert2Service.successAlert(response.message);
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async salvarQuestionario() {
    if (!this.respostaVazia(this.resposta36)) {
      this.resposta36.parecer_pergunta_id = 36;
      this.resposta36 = await this.atualizarResposta(this.resposta36);
    }
    if (!this.respostaVazia(this.resposta37)) {
      this.resposta37.parecer_pergunta_id = 37;
      this.resposta37 = await this.atualizarResposta(this.resposta37);
    }
    if (!this.respostaVazia(this.resposta38)) {
      this.resposta38.parecer_pergunta_id = 38;
      this.resposta38 = await this.atualizarResposta(this.resposta38);
    }
    if (!this.respostaVazia(this.resposta39)) {
      this.resposta39.parecer_pergunta_id = 39;
      this.resposta39 = await this.atualizarResposta(this.resposta39);
    }
    if (!this.respostaVazia(this.resposta40)) {
      this.resposta40.parecer_pergunta_id = 40;
      this.resposta40 = await this.atualizarResposta(this.resposta40);
    }
    if (!this.respostaVazia(this.resposta41)) {
      this.resposta41.parecer_pergunta_id = 41;
      this.resposta41 = await this.atualizarResposta(this.resposta41);
    }
    if (!this.respostaVazia(this.resposta42)) {
      this.resposta42.parecer_pergunta_id = 42;
      this.resposta42 = await this.atualizarResposta(this.resposta42);
    }
    if (!this.respostaVazia(this.resposta43)) {
      this.resposta43.parecer_pergunta_id = 43;
      this.resposta43 = await this.atualizarResposta(this.resposta43);
    }
    if (!this.respostaVazia(this.resposta44)) {
      this.resposta44.parecer_pergunta_id = 44;
      this.resposta44 = await this.atualizarResposta(this.resposta44);
    }
    if (!this.respostaVazia(this.resposta45)) {
      this.resposta45.parecer_pergunta_id = 45;
      this.resposta45 = await this.atualizarResposta(this.resposta45);
    }
    if (!this.respostaVazia(this.resposta46)) {
      this.resposta46.parecer_pergunta_id = 46;
      this.resposta46 = await this.atualizarResposta(this.resposta46);
    }
    if (!this.respostaVazia(this.resposta47)) {
      this.resposta47.parecer_pergunta_id = 47;
      this.resposta47 = await this.atualizarResposta(this.resposta47);
    }
    if (!this.respostaVazia(this.resposta48)) {
      this.resposta48.parecer_pergunta_id = 48;
      this.resposta48 = await this.atualizarResposta(this.resposta48);
    }
    if (!this.respostaVazia(this.resposta49)) {
      this.resposta49.parecer_pergunta_id = 49;
      this.resposta49 = await this.atualizarResposta(this.resposta49);
    }
    if (!this.respostaVazia(this.resposta50)) {
      this.resposta50.parecer_pergunta_id = 50;
      this.resposta50 = await this.atualizarResposta(this.resposta50);
    }
    if (!this.respostaVazia(this.resposta51)) {
      this.resposta51.parecer_pergunta_id = 51;
      this.resposta51 = await this.atualizarResposta(this.resposta51);
    }
    if (!this.respostaVazia(this.resposta52)) {
      this.resposta52.parecer_pergunta_id = 52;
      this.resposta52 = await this.atualizarResposta(this.resposta52);
    }
    if (!this.respostaVazia(this.resposta53)) {
      this.resposta53.parecer_pergunta_id = 53;
      this.resposta53 = await this.atualizarResposta(this.resposta53);
    }
    if (!this.respostaVazia(this.resposta54)) {
      this.resposta54.parecer_pergunta_id = 54;
      this.resposta54 = await this.atualizarResposta(this.resposta54);
    }
    if (!this.respostaVazia(this.resposta55)) {
      this.resposta55.parecer_pergunta_id = 55;
      this.resposta55 = await this.atualizarResposta(this.resposta55);
    }
    if (!this.respostaVazia(this.resposta56)) {
      this.resposta56.parecer_pergunta_id = 56;
      this.resposta56 = await this.atualizarResposta(this.resposta56);
    }
    if (!this.respostaVazia(this.resposta57)) {
      this.resposta57.parecer_pergunta_id = 57;
      this.resposta57 = await this.atualizarResposta(this.resposta57);
    }
    if (!this.respostaVazia(this.resposta58)) {
      this.resposta58.parecer_pergunta_id = 58;
      this.resposta58 = await this.atualizarResposta(this.resposta58);
    }
    if (!this.respostaVazia(this.resposta59)) {
      this.resposta59.parecer_pergunta_id = 59;
      this.resposta59 = await this.atualizarResposta(this.resposta59);
    }
    if (!this.respostaVazia(this.resposta60)) {
      this.resposta60.parecer_pergunta_id = 60;
      this.resposta60 = await this.atualizarResposta(this.resposta60);
    }
    if (!this.respostaVazia(this.resposta61)) {
      this.resposta61.parecer_pergunta_id = 61;
      this.resposta61 = await this.atualizarResposta(this.resposta61);
    }
    if (!this.respostaVazia(this.resposta62)) {
      this.resposta62.parecer_pergunta_id = 62;
      this.resposta62 = await this.atualizarResposta(this.resposta62);
    }
    if (!this.respostaVazia(this.resposta63)) {
      this.resposta63.parecer_pergunta_id = 63;
      this.resposta63 = await this.atualizarResposta(this.resposta63);
    }
    if (!this.respostaVazia(this.resposta64)) {
      this.resposta64.parecer_pergunta_id = 64;
      this.resposta64 = await this.atualizarResposta(this.resposta64);
    }
    if (!this.respostaVazia(this.resposta65)) {
      this.resposta65.parecer_pergunta_id = 65;
      this.resposta65 = await this.atualizarResposta(this.resposta65);
    }
    if (!this.respostaVazia(this.resposta66)) {
      this.resposta66.parecer_pergunta_id = 66;
      this.resposta66 = await this.atualizarResposta(this.resposta66);
    }
    if (!this.respostaVazia(this.resposta67)) {
      this.resposta67.parecer_pergunta_id = 67;
      this.resposta67 = await this.atualizarResposta(this.resposta67);
    }
    if (!this.respostaVazia(this.resposta68)) {
      this.resposta68.parecer_pergunta_id = 68;
      this.resposta68 = await this.atualizarResposta(this.resposta68);
    }
    if (!this.respostaVazia(this.resposta69)) {
      this.resposta69.parecer_pergunta_id = 69;
      this.resposta69 = await this.atualizarResposta(this.resposta69);
    }
    if (!this.respostaVazia(this.resposta70)) {
      this.resposta70.parecer_pergunta_id = 70;
      this.resposta70 = await this.atualizarResposta(this.resposta70);
    }
    if (!this.respostaVazia(this.resposta71)) {
      this.resposta71.parecer_pergunta_id = 71;
      this.resposta71 = await this.atualizarResposta(this.resposta71);
    }
    if (!this.respostaVazia(this.resposta72)) {
      this.resposta72.parecer_pergunta_id = 72;
      this.resposta72 = await this.atualizarResposta(this.resposta72);
    }
    if (!this.respostaVazia(this.resposta73)) {
      this.resposta73.parecer_pergunta_id = 73;
      this.resposta73 = await this.atualizarResposta(this.resposta73);
    }
    if (!this.respostaVazia(this.resposta74)) {
      this.resposta74.parecer_pergunta_id = 74;
      this.resposta74 = await this.atualizarResposta(this.resposta74);
    }
    if (!this.respostaVazia(this.resposta75)) {
      this.resposta75.parecer_pergunta_id = 75;
      this.questionarioFormando.indicacaoFC = parseInt(
        this.resposta75.resposta,
        10
      );
      this.resposta75 = await this.atualizarResposta(this.resposta75);
    }
    if (!this.respostaVazia(this.resposta76)) {
      this.resposta76.parecer_pergunta_id = 76;
      this.questionarioFormando.justificativaFC = this.resposta76.resposta;
      this.resposta76 = await this.atualizarResposta(this.resposta76);
    }
    if (!this.respostaVazia(this.resposta77)) {
      this.resposta77.parecer_pergunta_id = 77;
      this.caminhoFormativoFormando.caminhoFormativoSugeridoFC =
        this.resposta77.resposta;
      this.questionarioFormando.caminhoFormativoSugeridoFC =
        this.resposta77.resposta;
      this.resposta77 = await this.atualizarResposta(this.resposta77);
    }

    // let status = this.definirStatusQuestionario();
    const caminhoFormativoSugeridoFC =
      this.caminhoFormativoFormando.caminhoFormativoSugeridoFC;
    await this.atualizarParecerResponsavel('INICIADO');
    if (!this.caminhoFormativoFormando.id) {
      await this.carregarCaminhoFormativo();
      this.caminhoFormativoFormando.caminhoFormativoSugeridoFC =
        caminhoFormativoSugeridoFC;
    }
    this.atualizarCaminhoFormativo();
    this.atualizarQuestionarioFormando();
  }

  definirStatusQuestionario(): string {
    let status = 'INICIADO';

    let todoRespondido = false;

    if (
      !this.respostaVazia(this.resposta36) &&
      !this.respostaVazia(this.resposta37) &&
      !this.respostaVazia(this.resposta38) &&
      !this.respostaVazia(this.resposta39) &&
      !this.respostaVazia(this.resposta40) &&
      !this.respostaVazia(this.resposta41) &&
      !this.respostaVazia(this.resposta42) &&
      !this.respostaVazia(this.resposta43) &&
      !this.respostaVazia(this.resposta44) &&
      !this.respostaVazia(this.resposta45) &&
      !this.respostaVazia(this.resposta46) &&
      !this.respostaVazia(this.resposta47) &&
      // !this.respostaVazia(this.resposta33) && - Aqui é o caminho formativo proposto pelo FC
      !this.respostaVazia(this.resposta48) &&
      !this.respostaVazia(this.resposta49) &&
      !this.respostaVazia(this.resposta40) &&
      !this.respostaVazia(this.resposta51) &&
      !this.respostaVazia(this.resposta52) &&
      !this.respostaVazia(this.resposta53) &&
      !this.respostaVazia(this.resposta54) &&
      !this.respostaVazia(this.resposta55) &&
      !this.respostaVazia(this.resposta56) &&
      !this.respostaVazia(this.resposta57) &&
      !this.respostaVazia(this.resposta58) &&
      !this.respostaVazia(this.resposta59) &&
      !this.respostaVazia(this.resposta60) &&
      !this.respostaVazia(this.resposta61) &&
      !this.respostaVazia(this.resposta62) &&
      !this.respostaVazia(this.resposta63) &&
      !this.respostaVazia(this.resposta64) &&
      !this.respostaVazia(this.resposta65) &&
      !this.respostaVazia(this.resposta66) &&
      !this.respostaVazia(this.resposta67) &&
      !this.respostaVazia(this.resposta68) &&
      !this.respostaVazia(this.resposta69) &&
      !this.respostaVazia(this.resposta70) &&
      !this.respostaVazia(this.resposta71) &&
      !this.respostaVazia(this.resposta72) &&
      !this.respostaVazia(this.resposta73) &&
      !this.respostaVazia(this.resposta74) &&
      !this.respostaVazia(this.resposta75) &&
      !this.respostaVazia(this.resposta76) &&
      !this.respostaVazia(this.resposta77)
    ) {
      todoRespondido = true;
    }

    if (todoRespondido) {
      status = 'ENVIADO';
    }

    return status;
  }

  respostaVazia(resposta: any): boolean {
    return (
      resposta.resposta == null ||
      resposta.resposta == undefined ||
      resposta.resposta == ''
    );
  }

  async enviarQuestionario(): Promise<any> {
    await this.salvarQuestionario();
    await this.atualizarParecerResponsavel('ENVIADO');
    this.router.navigate(['secured/parecer-agradecimento']);
  }

  async atualizarParecerResponsavel(status: string): Promise<any> {
    try {
      if (this.parecerResponsavel.id != null) {
        this.parecerResponsavel.status = status;
        if (status == 'ENVIADO') {
          this.parecerResponsavel.data_envio = new Date();
        }
        let response = await this.parecerResponsavelService.atualizar(
          this.parecerResponsavel.id,
          this.parecerResponsavel
        );
        this.parecerResponsavel = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
}
