import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
export class CasasComunitariasListarComponent {
    constructor(regionalService, missaoService, casaComunitariaService, elementoGrupoDominioService, swtAlert2Service) {
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.regionais = new Array();
        this.missoes = new Array();
        this.casasComunitaria = new Array();
        this.statusCasaComunitaria = new Array();
        this.busca = {
            nome: null,
            ano: null,
            regionalId: null,
            missaoId: null,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            statusId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.carregarRegionais();
        this.carregarStatusCasaComunitaria();
        this.buscarCasasComunitaria();
    }
    ngOnDestroy() {
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarCasasComunitaria();
    }
    carregarStatusCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: 'CASA_COMUNITARIA_STATUS'
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.statusCasaComunitaria = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarRegionais() {
        this.regionalService.findAll().then((lista) => {
            this.regionais = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    carregarMissoes() {
        this.missaoService.porRegional(this.busca.regionalId).then((response) => {
            this.missoes = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    buscarCasasComunitaria() {
        this.casaComunitariaService.buscarCasasComunitaria(this.busca).then((response) => {
            this.casasComunitaria = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    deletarCasaComunitaria(id) {
        if (confirm('Deseja deletar esta casa comunitaria?')) {
            this.casaComunitariaService.delete(id)
                .then((response) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarCasasComunitaria();
            })
                .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}
