import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
export class ParecerResponsavelRegionalAutoridadeCasaComunitariaComponent {
    constructor(usuarioService, imagemService, pessoaService, casacomunitariaService, membroService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, discernimentoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casacomunitariaService = casacomunitariaService;
        this.membroService = membroService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.discernimentoService = discernimentoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCasaComunitariaAssociada = false;
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.buscaCasaComunitaria = {
                nome: null,
                ano: null,
                faseId: null,
                regionalId: null,
                missaoId: null,
                centroEvangelizacaoId: null,
                paisId: null,
                estadoId: null,
                pageNumber: 0,
                quantityOfElements: 300,
            };
            this.paginacaoConfig = {
                page: 0,
                size: 10,
                totalPages: 0,
                totalElements: 0,
            };
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarCorStatusVocacional();
            yield this.carregarMembrosCasaComunitaria();
        });
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarCorStatusVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.listaCorStatusVocacional =
                    yield this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao("COR_STATUS_VOCACIONAL");
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelRegionalPorTipo('CASACOMUNITARIA', this.questionarioId, this.paginacaoConfig.page, this.paginacaoConfig.size);
                this.membrosCasaComunitaria = response.content;
                this.paginacaoConfig.totalPages = response.totalPages;
                this.paginacaoConfig.totalElements = response.totalElements;
                if (this.membrosCasaComunitaria.length > 0) {
                    this.membrosCasaComunitaria = this.membrosCasaComunitaria.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
                    if (this.membrosCasaComunitaria[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                    }
                    this.membrosCasaComunitaria[i].editando = false;
                    this.membrosCasaComunitaria[i].indicacaoFCNome = this.buscarNomePedido(this.membrosCasaComunitaria[i].indicacaoFC);
                    this.membrosCasaComunitaria[i].indicacaoCLNome = this.buscarNomePedido(this.membrosCasaComunitaria[i].indicacaoCL);
                    this.membrosCasaComunitaria[i].indicacaoCRNome = this.buscarNomePedido(this.membrosCasaComunitaria[i].indicacaoCR);
                    if (this.membrosCasaComunitaria[i].corStatusVocacionalId) {
                        const cor = this.listaCorStatusVocacional.find(c => c.id === this.membrosCasaComunitaria[i].corStatusVocacionalId);
                        this.membrosCasaComunitaria[i].corStatusVocacionalValor = cor ? cor.valor : undefined;
                        this.membrosCasaComunitaria[i].corStatusVocacionalNome = cor ? cor.nome : undefined;
                    }
                    if (this.membrosCasaComunitaria[i].statusQuestionario == "NAOINICIADO"
                        || this.membrosCasaComunitaria[i].statusQuestionario == undefined
                        || this.membrosCasaComunitaria[i].statusQuestionario == null) {
                        this.membrosCasaComunitaria[i].statusQuestionario =
                            "Não Iniciado";
                    }
                    if (this.membrosCasaComunitaria[i].statusParecerFC == "NAOINICIADO"
                        || this.membrosCasaComunitaria[i].statusParecerFC == undefined
                        || this.membrosCasaComunitaria[i].statusParecerFC == null) {
                        this.membrosCasaComunitaria[i].statusParecerFC = "Não Iniciado";
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    buscarNomePedido(idPedido) {
        let nomePedido = '';
        if (idPedido) {
            switch (idPedido) {
                case 1:
                    nomePedido = 'Mudança de Nível';
                    break;
                case 2:
                    nomePedido = 'Permanecer no Nível';
                    break;
                case 3:
                    nomePedido = 'Realizar Primeiras Promessas';
                    break;
                case 4:
                    nomePedido = 'Realizar Promessas Definitivas';
                    break;
                case 5:
                    nomePedido = 'Desligamento CAL';
                    break;
                case 6:
                    nomePedido = 'Outro';
                    break;
                default:
                    nomePedido = null;
                    break;
            }
        }
        return nomePedido;
    }
    buscarNomeQuestionario(idQuestionario) {
        let nomeQuestionario = '';
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = 'Pedido para as Primeiras Promessas - CA';
                    break;
                case 5:
                    nomeQuestionario = 'Pedido para as Promessas Definitivas - CA';
                    break;
                case 6:
                    nomeQuestionario = 'Postulantes/Ingresso no Discipulado - CA';
                    break;
                case 7:
                    nomeQuestionario = 'Discipulos de Primeiro Ano - CA';
                    break;
                case 8:
                    nomeQuestionario = 'Renovação das Promessas - CA';
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    }
    carregarQuestionarioMembro(idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorMembrosId(idMembros);
                this.questionariosMembros = response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarArquivoPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    cadastrarConsideracaoConselhoLocal(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.atualizarQuestionarioFormando(membro);
                /*await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
                  membro
                ); */
                yield this.carregarMembrosCasaComunitaria();
                this.swtAlert2Service.successAlert("Parecer de consideração do Conselho Local atualizado com sucesso!");
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarQuestionarioFormando(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let responseQuestionario = yield this.questionarioFormandoService.buscarPorId(membro.idQuestionarioFormando);
                this.questionarioFormando = responseQuestionario;
                if (this.questionarioFormando.id != null) {
                    this.questionarioFormando.indicacaoCR = membro.indicacaoCR;
                    this.questionarioFormando.justificativaCR = membro.justificativaCR;
                    this.questionarioFormando.isDiscernimentoGG = membro.isDiscernimentoGG;
                    this.questionarioFormando.consideracaoConcorda = membro.consideracaoConcorda;
                    this.questionarioFormando.caminhoFormativoFeedback = membro.caminhoFormativoFeedback;
                    this.questionarioFormando.caminhoFormativoSugeridoFC = membro.caminhoFormativoSugeridoFC;
                    this.questionarioFormando.consideracaoCL = membro.consideracaoCL;
                    this.questionarioFormando.corStatusVocacionalId = membro.corStatusVocacionalId;
                    let response = yield this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando);
                    this.questionarioFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.carregarMembrosCasaComunitaria();
    }
    concordanciaConselhoLocal(membro) {
        if (membro.consideracaoConcorda === true) {
            membro.caminhoFormativoFeedback =
                membro.caminhoFormativoSugeridoFC;
            membro.consideracaoCL = "";
        }
        else {
            membro.caminhoFormativoFeedback = "";
        }
    }
    copiarTextoConsideracaoConselhoLocalParaFeedback(membro) {
        membro.caminhoFormativoFeedback = membro.consideracaoCL;
    }
    obterCorDaLinha(membro) {
        let cssObject = {};
        if (membro.corStatusVocacionalValor != null) {
            cssObject["background-color"] = membro.corStatusVocacionalValor;
        }
        if (membro.corStatusVocacionalNome != null &&
            membro.corStatusVocacionalNome.localeCompare("Desligamento") == 0) {
            cssObject["color"] = "#fff";
        }
        return cssObject;
    }
    truncateText(text, limit = 50) {
        if (!text) {
            return '';
        }
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    }
}
