<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem das Casas Comunitárias</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <!--div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarCasasComunitaria()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Casas Comunitarias</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncc_dtfund">Ano Fundação</label>
                                                <input [(ngModel)]="busca.ano" name="ncc_dtfund" type="number" class="form-control" id="ncc_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncc_r">Regional</label>
                                                <select class="form-control" id="ncc_r" [(ngModel)]="busca.regionalId" (change)="carregarMissoes()" name="ncc_r">
                                                    <option [value]="null" i18n>Selecione um Regional</option>
                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncc_missao">Missão</label>
                                                <select class="form-control" id="ncc_missao" [(ngModel)]="busca.missaoId" name="ncc_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div-->
                <div class="row">
                    <div class="col-md-12">
                        <table-component [tituloSessao]="'Casas Comunitarias'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th>Missão</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let cc of casasComunitaria">
                                <td>{{cc.id}}</td>
                                <td>{{cc.nome}}</td>
                                <td>{{cc.missaoNome}}</td>
                                <td class="text-center">
                                    <!--button class="btn btn-sm btn-default ml-1"
                                        routerLink="../../casacomunitaria/{{cc.id}}/gerenciar" title="Gerenciar Casa Comunitária"
                                        alt="Gerenciar Casa Comunitária"><i class="fas fa-cog"></i></button-->
                                    <button class="btn btn-sm btn-primary ml-1"
                                        routerLink="../../casacomunitaria/discernimento/{{cc.id}}"
                                        title="Discernimentos da Casa Comunitária" alt="Discernimentos da Casa Comunitária"><i
                                            class="fas fa-list"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>


</secured>