/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../layout/secured.component.ngfactory";
import * as i3 from "../../layout/secured.component";
import * as i4 from "../../../login/service/login.service";
import * as i5 from "../../wop/usuario/service/usuario.service";
import * as i6 from "../../layout/routermanager.service";
import * as i7 from "../../../core/services/app-route-change.service";
import * as i8 from "../../wop/aceitetermouso/service/aceitetermouso.service";
import * as i9 from "../../wop/aceitetermotratamentodados/service/aceitetermotratamentodados.service";
import * as i10 from "../cadastropublico/service/cadastro.publico.local.service";
import * as i11 from "../../../core/swtalert2/swtalert2.service";
import * as i12 from "../../../core/table/table.component.ngfactory";
import * as i13 from "../../../core/table/table.component";
import * as i14 from "@angular/common";
import * as i15 from "./casascomunitarias.listar.component";
import * as i16 from "../../wop/regional/service/regional.service";
import * as i17 from "../../wop/missao/service/missao.service";
import * as i18 from "../../wop/casacomunitaria/service/casacomunitaria.service";
import * as i19 from "../../wop/grupodominio/service/elementogrupodominio.service";
var styles_CasasComunitariasListarComponent = [];
var RenderType_CasasComunitariasListarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CasasComunitariasListarComponent, data: {} });
export { RenderType_CasasComunitariasListarComponent as RenderType_CasasComunitariasListarComponent };
function View_CasasComunitariasListarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "tr", [["tablebody", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["alt", "Discernimentos da Casa Comunit\u00E1ria"], ["class", "btn btn-sm btn-primary ml-1"], ["title", "Discernimentos da Casa Comunit\u00E1ria"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "fas fa-list"]], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = i0.ɵinlineInterpolate(1, "../../casacomunitaria/discernimento/", _v.context.$implicit.id, ""); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.nome; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.missaoNome; _ck(_v, 6, 0, currVal_2); }); }
export function View_CasasComunitariasListarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "secured", [], null, null, null, i2.View_SecuredComponent_0, i2.RenderType_SecuredComponent)), i0.ɵdid(1, 4308992, null, 0, i3.SecuredComponent, [i4.LoginService, i5.UsuarioService, i6.RouterManagerService, i7.AppRouteChangeService, i8.AceiteTermoService, i9.AceiteTermoTratamentoDadosService, i10.CadastroPublicoLocalService, i11.SwtAlert2Service, i1.Router, i0.Renderer2], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 22, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "section", [["class", "content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Listagem das Casas Comunit\u00E1rias"])), (_l()(), i0.ɵeld(9, 0, null, null, 15, "section", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 14, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 12, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 11, "table-component", [], null, [[null, "eventoBotao"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventoBotao" === en)) {
        var pd_0 = (_co.cliqueNoBotaoListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_TableComponent_0, i12.RenderType_TableComponent)), i0.ɵdid(14, 638976, null, 0, i13.TableComponent, [], { cols: [0, "cols"], rows: [1, "rows"], totalDePaginas: [2, "totalDePaginas"], tituloSessao: [3, "tituloSessao"] }, { eventoBotao: "eventoBotao" }), (_l()(), i0.ɵeld(15, 0, null, 0, 7, "tr", [["tableheader", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "th", [["style", "width: 10px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["#"])), (_l()(), i0.ɵeld(18, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nome"])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Miss\u00E3o"])), (_l()(), i0.ɵeld(22, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_CasasComunitariasListarComponent_1)), i0.ɵdid(24, 278528, null, 0, i14.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = 4; var currVal_1 = _co.busca.quantityOfElements; var currVal_2 = _co.totalDePaginas; var currVal_3 = "Casas Comunitarias"; _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.casasComunitaria; _ck(_v, 24, 0, currVal_4); }, null); }
export function View_CasasComunitariasListarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "search-celula-af", [], null, null, null, View_CasasComunitariasListarComponent_0, RenderType_CasasComunitariasListarComponent)), i0.ɵdid(1, 245760, null, 0, i15.CasasComunitariasListarComponent, [i16.RegionalService, i17.MissaoService, i18.CasaComunitariaService, i19.ElementoGrupoDominioService, i11.SwtAlert2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CasasComunitariasListarComponentNgFactory = i0.ɵccf("search-celula-af", i15.CasasComunitariasListarComponent, View_CasasComunitariasListarComponent_Host_0, {}, {}, []);
export { CasasComunitariasListarComponentNgFactory as CasasComunitariasListarComponentNgFactory };
